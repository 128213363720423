export const cidades = [{
        title: 'Campinas',
        state: 'SP',
        image: require('../images/photos_city/campinas-fundo.jpg'),
        imageApp: require('../images/photos_city/Campinas.png'),
        imageAppPB: require('../images/photos_city/Campinas-pb.png'),
        linkTo: 'https://www.eufaco.minhacampinas.org.br',
        appLink: 'http://app.vota.org.br/',
        enableApp: true,
    },
    {
        title: 'João Pessoa',
        state: 'PB',
        image: require('../images/photos_city/jampa-fundo.png'),
        imageApp: require('../images/photos_city/Jampa.png'),
        imageAppPB: require('../images/photos_city/Jampa-pb.png'),
        linkTo: 'https://www.minhajampa.org.br/#block-14338',
        appLink: 'https://app.vota.org.br/',
        enableApp: true,
    },
    {
        title: 'Porto Alegre',
        state: 'RS',
        image: require('../images/photos_city/poa-fundo.jpg'),
        imageApp: require('../images/photos_city/Poa.png'),
        imageAppPB: require('../images/photos_city/Poa-pb.png'),
        linkTo: 'https://www.minhaportoalegre.org.br/#block-12312',
        appLink: 'https://app.vota.org.br/',
        enableApp: true,
    },
    {
        title: 'Recife',
        state: 'PB',
        image: require('../images/photos_city/recife-fundo.jpg'),
        imageApp: require('../images/photos_city/Recife.png'),
        imageAppPB: require('../images/photos_city/Recife-pb.png'),
        linkTo: 'https://www.meurecife.org.br/#block-4456',
        appLink: 'https://app.vota.org.br/',
        enableApp: true,
    },
    {
        title: 'Americana',
        state: 'SP',
        // image: require('../images/photos_city/campinas-fundo.jpg'),
        imageApp: require('../images/photos_city/Americana.png'),
        imageAppPB: require('../images/photos_city/Americana-pb.png'),
        // linkTo: 'https://www.eufaco.minhacampinas.org.br',
        appLink: 'http://app.vota.org.br/',
        enableApp: false,
    },
    // {
    //   title: 'Campina Grande',
    //   image: require('../images/photos_city/campina-grande-fundo.jpg'),
    //   linkTo: 'https://www.facebook.com/MinhaCampinaRNC/',
    // },
]
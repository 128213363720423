export const supporters = [
  {
    nome: 'Politize!',
    logo: require('../images/supporters/politize.png'),
    link: 'https://www.politize.com.br/'
  },
  {
    nome: 'Casa Hacker!',
    logo: require('../images/supporters/casahacker.png'),
    link: 'https://casahacker.org/'
  },
]

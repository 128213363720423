export const teamMembers = [
    {
        name: 'Amanda Yoshiizumi',
        photo: require('../images/photos_team/Amanda.png'),
        role: 'UX/UI Designer',
    },
    {
        name: 'Andre Bordignon ',
        photo: require('../images/photos_team/AndreBordignon.png'),
        role: 'IFSP e Minha Campinas',
    },
    {
        name: 'Camila Oliveira',
        photo: require('../images/photos_team/CamilaOliveira.png'),
        role: 'Minha Porto Alegre',
    },
    {
        name: 'Claudia Oliveira',
        photo: require('../images/photos_team/ClaudiaOliveira.png'),
        role: 'Minha Campinas',
    },
    {
        name: 'Elisa Bonotto - Zi',
        photo: require('../images/photos_team/ZiBonotto.png'),
        role: 'Minha Porto Alegre',
    },
    {
        name: 'Elisa Mueller',
        photo: require('../images/photos_team/ElisaMueller.png'),
        role: 'Minha Campinas',
    },
    {
        name: 'Gabriel Ribeiro',
        photo: require('../images/photos_team/GabrielRibeiro.png'),
        role: 'Front-end',
    },
    {
        name: 'Guilherme Luchesi',
        photo: require('../images/photos_team/GuilhermeLuchesi.png'),
        role: 'Front-end',
    },
    {
        name: 'Henrique Degrecci',
        photo: require('../images/photos_team/HenriqueDegrecci.png'),
        role: 'Front-end',
    },
    {
        name: 'José Ronaldo',
        photo: require('../images/photos_team/JoseRonaldo.png'),
        role: 'Minha Campina',
    },
    {
        name: 'João Aleixo',
        photo: require('../images/photos_team/JoaoAleixo.png'),
        role: 'IFSP e Front-end',
    },
    {
        name: 'Juliana Patete',
        photo: require('../images/photos_team/JulianaPatete.png'),
        role: 'Minha Campinas',
    },
    {
        name: 'Lucas Guima',
        photo: require('../images/photos_team/LucasGuima.png'),
        role: 'Back-End',
    },
    {
        name: 'Marcelo Nisida',
        photo: require('../images/photos_team/MarceloNisida.png'),
        role: 'Minha Campinas',
    },
    {
        name: 'Victor Miguez',
        photo: require('../images/photos_team/VictorMiguez.png'),
        role: 'Full Stack',
    },
    {
        name: 'Vitor Perin',
        photo: require('../images/photos_team/VitorPerin.png'),
        role: 'Back-end',
    },
    {
        name: 'Jerlan Alves',
        photo: require('../images/photos_team/Jerlan.png'),
        role: 'Minha Jampa',
    },
    {
        name: 'Silvia Octaviano',
        photo: require('../images/photos_team/SilviaOctaviano.png'),
        role: 'Minha Campinas',
    },
    {
        name: 'Joelma Santos',
        photo: require('../images/photos_team/JoelmaSantos.png'),
        role: 'Meu Recife',
    },
    {
        name: 'Julia de Paula',
        photo: require('../images/photos_team/JuliadePaula.png'),
        role: 'Meu Recife',
    },
    {
        name: 'Gabriel Sá',
        photo: require('../images/photos_team/GabrielSa.png'),
        role: 'Meu Recife',
    },
    {
        name: 'Géssica de Freitas',
        photo: require('../images/photos_team/GessicaDeFreitas.png'),
        role: 'Minha Campinas',
    },
    {
        name: 'Soso',
        photo: require('../images/photos_team/Soso.png'),
        role: 'Meu Porto Alegre',
    },

]
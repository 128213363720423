export const contatos = [
  // {
  //   cidade: 'Campina Grande',
  //   contato: 'contato@minhacampina.org.br'
  // },
  {
    cidade: 'Campinas',
    contato: 'contato@minhacampinas.org.br'
  },
  {
    cidade: 'João Pessoa',
    contato: 'contato@minhajampa.org.br'
  },
  {
    cidade: 'Porto Alegre',
    contato: 'contato@minhaportoalegre.org.br'
  },
  {
    cidade: 'Recife',
    contato: 'contato@meurecife.org.br'
  },
]